import { ReadableViewTypes } from '@allvit-labs/react-client';
import Dexie, { type EntityTable } from 'dexie';

export type PersistedReaderSettings = ReadableViewTypes & { id: number; fixToolbars?: boolean };

const db = new Dexie('ReaderSettings') as Dexie & {
    readerSettings: EntityTable<
        PersistedReaderSettings,
        'id' // primary key "id" (for the typings only)
    >;
};

// Schema declaration:
db.version(1).stores({
    readerSettings: '++id',
});

export const mapSettingKeysFrontendToReadable: { fontSize: string; [k: string]: string } = {
    fontSize: 'zoom',
    showHighlights: 'showAnnotations',
    margin: 'contentWidth',
};

const persistReaderSetting = async (key: string, value: any) => {
    const settings = await db.readerSettings.get(1);
    if (!settings) {
        await db.readerSettings.put({ id: 1, [mapSettingKeysFrontendToReadable[key] ?? key]: value }, 1);
    }
    await db.readerSettings.update(1, { [mapSettingKeysFrontendToReadable[key] ?? key]: value });
};

export { db, persistReaderSetting };
